import { createAction, createReducer, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from '../../../config';
import { fetchApi, getOptions } from '../../sagas';
import { DiscussionFilter, PageOfDiscussions, State } from './types';
import { Status } from '../../../enums/Status';

const errorAction = createAction<string>('discussions/saga/error');

const actions = {
  fetchDiscussions: {
    initiated: createAction<DiscussionFilter>('discussions/saga/fetchDiscussions/initiated'),
    fulfilled: createAction<PageOfDiscussions>('discussions/saga/fetchDiscussions/fulfilled'),
    rejected: errorAction,
  },
  setDiscussionsStatusFilter: createAction<DiscussionFilter>('posts/saga/setDiscussionsStatusFilter/initiated'),
};

export const fetchDiscussionsAction = actions.fetchDiscussions.initiated;

export const sagas = [takeLatest(fetchDiscussionsAction, fetchDiscussions)];

const initialState: State = {
  discussions: [],
  discussionsStatusFilter: { status: Status.PendingReview },
  nextPageId: null, // pagination value for discussions api
  error: null,
  isLoading: true,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.setDiscussionsStatusFilter, (state, action) => {
    state.discussionsStatusFilter = action.payload;
  });
  builder.addCase(actions.fetchDiscussions.fulfilled, (state, action) => {
    const { nextPageId, discussions } = action.payload;
    state.isLoading = false;
    state.nextPageId = nextPageId;
    state.discussions.splice(0, state.discussions.length, ...discussions);
  });
  builder.addCase(errorAction, (state, action) => {
    console.error(action);
    state.error = 'Http call for discussions failed';
    if (typeof action.payload === 'object') {
      if (Object.values(action.payload!).length > 0) {
        state.error = JSON.stringify(action.payload);
      }
    }
    state.isLoading = false;
  });
  builder.addMatcher(isAnyOf(actions.fetchDiscussions.initiated), (state) => {
    state.isLoading = true;
  });
});

export function* fetchDiscussions(action: PayloadAction<DiscussionFilter>) {
  const { status } = action.payload;
  const statusObject = status ? { status } : {};
  yield put(actions.setDiscussionsStatusFilter(statusObject));
  yield call(
    fetchApi,
    `${API_BASE_URL}/v1/community/discussions?pageSize=10000${status ? `&status=${status}` : ``}`,
    getOptions(),
    actions.fetchDiscussions.fulfilled,
    actions.fetchDiscussions.rejected
  );
}
