import { PayloadAction, createAction, createReducer, isAnyOf } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from '../../../config';
import { fetchApi, getOptions } from '../../sagas';
import { Comment, CommentSelector, CommentsFilter, PageOfComments, State } from './types';
import { fetchPostByIdAction } from '../posts';
import { Status } from '../../../enums/Status';
import { Saga } from '../../sagasTypes';
import { fetchDiscussionsAction } from '../discussions';

const errorAction = createAction<string>('comments/saga/error');

const actions = {
  fetchCommentById: {
    initiated: createAction<CommentSelector>('comments/saga/fetchCommentById/initiated'),
    fulfilled: createAction<Comment>('comments/saga/fetchCommentById/fulfilled'),
    rejected: errorAction,
  },
  fetchComments: {
    initiated: createAction<CommentsFilter>('comments/saga/fetchComments/initiated'),
    fulfilled: createAction<PageOfComments>('comments/saga/fetchComments/fulfilled'),
    rejected: errorAction,
  },
  updateComment: {
    initiated: createAction<CommentSelector & CommentsFilter>('posts/saga/updateComment/initiated'),
    fulfilled: createAction<void>('posts/saga/updateComment/fulfilled'),
    rejected: errorAction,
  },
  setCommentsStatusFilter: createAction<CommentsFilter>('posts/saga/setCommentsStatusFilter/initiated'),
  resetSelectedComment: createAction<void>('comments/saga/resetComment/initiated'),
  setSelectedComment: createAction<Comment>('comments/saga/setSelectedComment/initiated'),
};

export const fetchCommentsAction = actions.fetchComments.initiated;
export const updateCommentAction = actions.updateComment.initiated;
export const resetSelectedCommentAction = actions.resetSelectedComment;
export const setSelectedCommentAction = actions.setSelectedComment;
export const fetchCommentByIdAction = actions.fetchCommentById.initiated;

export const sagas = [
  takeLatest(fetchCommentsAction, fetchComments),
  takeLatest(fetchCommentByIdAction, fetchCommentById),
  takeLatest(updateCommentAction, updateComment),
];

const initialState: State = {
  selectedComment: null,
  commentsStatusFilter: { status: Status.PendingReview },
  comments: [],
  nextPageId: null, // pagination value for comments api
  error: null,
  isLoading: true,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.resetSelectedComment, (state) => {
    state.selectedComment = null;
  });
  builder.addCase(actions.setCommentsStatusFilter, (state, action) => {
    state.commentsStatusFilter = action.payload;
  });
  builder.addCase(actions.setSelectedComment, (state, action) => {
    state.selectedComment = action.payload;
  });
  builder.addCase(actions.fetchComments.fulfilled, (state, action) => {
    const { nextPageId, comments } = action.payload;
    state.isLoading = false;
    state.nextPageId = nextPageId;
    state.comments.splice(0, state.comments.length, ...comments);
  });
  builder.addCase(actions.fetchCommentById.fulfilled, (state, action) => {
    if (!action.payload) {
      return;
    }
    state.selectedComment = action.payload;
    state.isLoading = false;
  });
  builder.addCase(errorAction, (state, action) => {
    console.error(action);
    state.error = 'Http call for comments failed';
    if (typeof action.payload === 'object') {
      if (Object.values(action.payload!).length > 0) {
        state.error = JSON.stringify(action.payload);
      }
    }
    state.isLoading = false;
  });
  builder.addMatcher(isAnyOf(actions.fetchComments.initiated, actions.fetchCommentById.initiated), (state) => {
    state.isLoading = true;
  });
});

function* fetchCommentById(action: PayloadAction<CommentSelector>) {
  const { commentId } = action.payload;

  yield call(
    fetchApi,
    `${API_BASE_URL}/v1/community/comments/${commentId}`,
    getOptions(),
    actions.fetchCommentById.fulfilled,
    actions.fetchCommentById.rejected
  );

  const selectedComment: Comment = yield select((state) => state.comments.selectedComment);
  yield put(fetchPostByIdAction({ postId: selectedComment.postId }));
}

function* updateComment(action: PayloadAction<CommentSelector & CommentsFilter>): Saga {
  const { commentId, status } = action.payload;
  if (!commentId || !status) {
    return;
  }

  yield call(
    fetchApi,
    `${API_BASE_URL}/v1/community/comments/${commentId}`,
    getOptions('PUT', { status }),
    actions.updateComment.fulfilled,
    actions.updateComment.rejected
  );

  // refetch data after updating a comment
  const commentsStatusFilter = yield select((state) => state.comments.commentsStatusFilter);
  yield put(fetchCommentsAction(commentsStatusFilter));

  const discussionsStatusFilter = yield select((state) => state.discussions.discussionsStatusFilter);
  yield put(fetchDiscussionsAction(discussionsStatusFilter));
}

export function* fetchComments(action: PayloadAction<CommentsFilter>) {
  const { status } = action.payload;
  const statusObject = status ? { status } : {};
  yield put(actions.setCommentsStatusFilter(statusObject));
  yield call(
    fetchApi,
    `${API_BASE_URL}/v1/community/comments?pageSize=10000${status ? `&status=${status}` : ``}`,
    getOptions(),
    actions.fetchComments.fulfilled,
    actions.fetchComments.rejected
  );
}
