import { all, call, put } from 'redux-saga/effects'; //, takeLatest
import { PayloadAction } from '@reduxjs/toolkit';
import HttpStatusCodes from 'http-status-codes';
// import { loginRequestAuthAction, login, logoutSuccessAuthAction, logout } from './modules/auth';
import { sagas as postsSagas } from './modules/posts';
import { sagas as complaintsSagas } from './modules/complaints';
import { sagas as commentsSagas } from './modules/comments';
import { sagas as discussionsSagas } from './modules/discussions';
import { Saga } from './sagasTypes';
import { getMarket, getToken } from '../utils/AuthService';

export default function* rootSaga() {
  yield all([
    // takeLatest(loginRequestAuthAction, login),
    // takeLatest(logoutSuccessAuthAction, logout),
    ...complaintsSagas,
    ...postsSagas,
    ...commentsSagas,
    ...discussionsSagas,
  ]);
}

export function* fetchApi(
  url: string,
  options: RequestInit,
  successAction: (result: any) => PayloadAction<any>,
  failureAction: (error: any) => PayloadAction<any>
): Saga {
  try {
    const { data, err, status } = yield call(fetchJson, url, options);

    if (!!data || status === 'success') {
      return yield put(successAction(data));
    } else {
      return yield put(failureAction(err));
    }
  } catch (e: any) {
    return yield put(failureAction(e.message));
  }
}

export function* fetchFile(
  url: string,
  options: RequestInit,
  successAction: (text: string) => PayloadAction<any>,
  failureAction: (errorMessage: string) => PayloadAction<any>
): Saga {
  const resp: Response = yield call(fetch, url, options);
  if (resp.status >= 300) {
    return yield put(failureAction(`Request for ${url} failed: ${resp}`));
  }
  const data: string = yield resp.text();
  return yield put(successAction(data));
}

export async function fetchJson(url: string, options?: RequestInit) {
  try {
    const data = await fetch(url, options);
    if (data.status === HttpStatusCodes.NO_CONTENT) {
      return { status: 'success' };
    }
    const resp = await data.json();
    if (resp.status === 'fail' || data.status >= 400) {
      return {
        status: 'fail',
        data: null,
        err: resp.data || `Request for ${url} failed: ${JSON.stringify(resp)}`,
      };
    }
    return resp;
  } catch (e: any) {
    return { err: e.message };
  }
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export function getOptions(method: HttpMethod = 'GET', bodyObject = {}, formData?: FormData): RequestInit {
  const isFormData = formData instanceof FormData;
  const headers = {
    // TODO: Uncomment below line, when an access with admin user is available
    //  Authorization: `Bearer ${localStorage.id_token}`,
    Authorization: `${getToken()}`,
    market: `${getMarket()}`,
    ...(!isFormData && { 'Content-Type': 'application/json' }),
  };
  const body = isFormData ? formData : JSON.stringify(bodyObject);
  const options = {
    method,
    headers,
    ...(method !== 'GET' && { body }),
  };

  return options;
}
