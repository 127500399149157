// Keep it flat and alphabetized

interface EnvironmentConfig {
  API_BASE_URL: string;
  auth0: {
    CLIENT_ID: string;
    DOMAIN: string;
    JWT_ISSUERS: string[];
  };
}

const allEnvironments: Record<string, EnvironmentConfig> = {
  local: {
    API_BASE_URL: 'http://localhost:3000',
    auth0: {
      CLIENT_ID: '',
      DOMAIN: 'carrot-sandbox.auth0.com',
      JWT_ISSUERS: ['https://carrot-sandbox.auth0.com/'],
    },
  },
  development: {
    API_BASE_URL: 'https://dev.api.nicorette-connect.kenvue.com',
    auth0: {
      CLIENT_ID: '',
      DOMAIN: 'carrot-sandbox.auth0.com',
      JWT_ISSUERS: ['https://carrot-sandbox.auth0.com/'],
    },
  },
  qa: {
    API_BASE_URL: 'https://qa.api.nicorette-connect.kenvue.com',
    auth0: {
      CLIENT_ID: '',
      DOMAIN: 'carrot-sandbox.auth0.com',
      JWT_ISSUERS: ['https://carrot-sandbox.auth0.com/'],
    },
  },
  uat: {
    API_BASE_URL: 'https://uat.api.nicorette-connect.kenvue.com',
    auth0: {
      CLIENT_ID: '',
      DOMAIN: 'carrot-sandbox.auth0.com',
      JWT_ISSUERS: ['https://carrot-sandbox.auth0.com/'],
    },
  },
  production: {
    API_BASE_URL: 'https://api.carrotsense.com',
    auth0: {
      CLIENT_ID: '',
      DOMAIN: 'carrot-production.auth0.com',
      JWT_ISSUERS: ['https://carrot-production.auth0.com/'],
    },
  },
};

const environmentConfig = Object.assign(
  {
    // Place environment variables that have common values here
  },
  allEnvironments[process.env.REACT_APP_ENV || 'development']
);

// Common config
export const { API_BASE_URL, auth0 } = environmentConfig;
