import { createAction, createReducer, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API_BASE_URL } from '../../../config';
import { fetchApi, getOptions } from '../../sagas';
import { PageOfPosts, Post, PostSelector, PostsFilter, State } from './types';
import { Status } from '../../../enums/Status';
import { Saga } from '../../sagasTypes';
import { fetchDiscussionsAction } from '../discussions';

const errorAction = createAction<string>('posts/saga/error');

const actions = {
  fetchPostById: {
    initiated: createAction<PostSelector>('posts/saga/fetchPostById/initiated'),
    fulfilled: createAction<Post>('posts/saga/fetchPostById/fulfilled'),
    rejected: errorAction,
  },
  fetchPosts: {
    initiated: createAction<PostsFilter>('posts/saga/fetchPosts/initiated'),
    fulfilled: createAction<PageOfPosts>('posts/saga/fetchPosts/fulfilled'),
    rejected: errorAction,
  },
  updatePost: {
    initiated: createAction<PostSelector & PostsFilter>('posts/saga/updatePost/initiated'),
    fulfilled: createAction<void>('posts/saga/updatePost/fulfilled'),
    rejected: errorAction,
  },
  setPostsStatusFilter: createAction<PostsFilter>('posts/saga/setPostsStatusFilter/initiated'),
  resetSelectedPost: createAction<void>('posts/saga/resetPost/initiated'),
  setSelectedPost: createAction<Post>('posts/saga/setSelectedPost/initiated'),
};

export const fetchPostByIdAction = actions.fetchPostById.initiated;
export const fetchPostsAction = actions.fetchPosts.initiated;
export const updatePostAction = actions.updatePost.initiated;
export const resetSelectedPostAction = actions.resetSelectedPost;
export const setSelectedPostAction = actions.setSelectedPost;

export const sagas = [
  takeLatest(fetchPostByIdAction, fetchPostById),
  takeLatest(fetchPostsAction, fetchPosts),
  takeLatest(updatePostAction, updatePost),
];

const initialState: State = {
  selectedPost: null,
  postsStatusFilter: { status: Status.PendingReview },
  posts: [],
  nextPageId: null, // pagination value for posts api
  error: null,
  isLoading: true,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.resetSelectedPost, (state) => {
    state.selectedPost = null;
  });
  builder.addCase(actions.setPostsStatusFilter, (state, action) => {
    state.postsStatusFilter = action.payload;
  });
  builder.addCase(actions.setSelectedPost, (state, action) => {
    state.selectedPost = action.payload;
  });
  builder.addCase(actions.fetchPostById.fulfilled, (state, action) => {
    if (!action.payload) {
      return;
    }

    state.selectedPost = action.payload;
    state.isLoading = false;
  });
  builder.addCase(actions.updatePost.fulfilled, (state) => {
    state.isLoading = false;
  });
  builder.addCase(actions.fetchPosts.fulfilled, (state, action) => {
    const { nextPageId, posts } = action.payload;
    state.isLoading = false;
    state.nextPageId = nextPageId;
    state.posts.splice(0, state.posts.length, ...posts);
  });
  builder.addCase(errorAction, (state, action) => {
    console.error(action);
    state.error = 'Http call for posts failed';
    if (typeof action.payload === 'object') {
      if (Object.values(action.payload!).length > 0) {
        state.error = JSON.stringify(action.payload);
      }
    }
    state.isLoading = false;
  });
  builder.addMatcher(isAnyOf(actions.fetchPostById.initiated, actions.fetchPosts.initiated), (state) => {
    state.isLoading = true;
  });
});

function* updatePost(action: PayloadAction<PostSelector & PostsFilter>): Saga {
  const { postId, status } = action.payload;
  if (!postId || !status) {
    return;
  }

  yield call(
    fetchApi,
    `${API_BASE_URL}/v1/community/posts/${postId}`,
    getOptions('PUT', { status }),
    actions.updatePost.fulfilled,
    actions.updatePost.rejected
  );

  // refetch data after updating a post
  const postsStatusFilter = yield select((state) => state.posts.postsStatusFilter);
  yield put(fetchPostsAction(postsStatusFilter));

  const discussionsStatusFilter = yield select((state) => state.discussions.discussionsStatusFilter);
  yield put(fetchDiscussionsAction(discussionsStatusFilter));
}

function* fetchPostById(action: PayloadAction<PostSelector>) {
  const { postId } = action.payload;
  if (!postId) {
    return;
  }

  yield call(
    fetchApi,
    `${API_BASE_URL}/v1/community/posts/${postId}`,
    getOptions(),
    actions.fetchPostById.fulfilled,
    actions.fetchPostById.rejected
  );
}

export function* fetchPosts(action: PayloadAction<PostsFilter>) {
  const { status } = action.payload;
  const statusObject = status ? { status } : {};
  yield put(actions.setPostsStatusFilter(statusObject));
  yield call(
    fetchApi,
    `${API_BASE_URL}/v1/community/posts?pageSize=10000${status ? `&status=${status}` : ``}`,
    getOptions(),
    actions.fetchPosts.fulfilled,
    actions.fetchPosts.rejected
  );
}
