import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider, SnackbarKey } from 'notistack';
import { configureStore, Middleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import momentUtils from '@date-io/moment';
import { createMuiTheme, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import App from './containers/App';
import rootSaga from './redux/sagas';
import reducer from './redux/modules/reducer';
import reportWebVitals from './reportWebVitals';

const sagaMiddleware = createSagaMiddleware();
const middleware: Middleware[] = [sagaMiddleware];
const isInProduction = process.env.NODE_ENV === 'production';

// if added, Logger must be last middleware
if (isInProduction === false) {
  middleware.push(createLogger({ collapsed: true }));
}

const store = configureStore({
  reducer,
  middleware,
  devTools: isInProduction === false,
});

sagaMiddleware.run(rootSaga);

export type CustomTheme = Theme & {
  palette: {
    appBar: {
      main: string;
      contrastText: string;
      versionText: string;
      light: string;
    };
  };
};

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        light: '#86e9c7',
        main: '#165D3A',
        dark: '#008062',
        contrastText: '#fff',
      },
      secondary: {
        light: '#eb7949',
        main: '#28808F',
        dark: '#c82e17',
        contrastText: '#fff',
      },
      text: {
        primary: '#222',
        secondary: '#555',
        disabled: '#222',
        hint: '#555',
      },
    },
  },
  {
    palette: {
      appBar: {
        main: '#292b2c',
        light: '#6EBF60',
        contrastText: '#eee',
        versionText: '#aaa',
      },
    },
  }
);

const useStyles = makeStyles(() => ({
  message: {
    width: '600px',
    wordBreak: 'break-word',
    '& #notistack-snackbar': {
      width: 'calc(100% - 5rem)',
    },
  },
}));

const Root = () => {
  const classes = useStyles();
  const notistackRef = React.createRef<SnackbarProvider>();

  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <Provider store={store}>
      <Router>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={momentUtils}>
            <SnackbarProvider
              ref={notistackRef}
              classes={{ root: classes.message }}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={2000}
              action={(key) => (
                <Button onClick={onClickDismiss(key)} style={{ color: 'white' }} size="small">
                  <DeleteIcon />
                </Button>
              )}
            >
              <App />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Router>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
