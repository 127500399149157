import { createAction, createReducer, PayloadActionCreator, PayloadAction } from '@reduxjs/toolkit';

export const selectPanelAction: PayloadActionCreator<string | null> = createAction('app/selectPanel');

interface State {
  selectedPanel: string | null;
}

const initialState: State = {
  selectedPanel: null,
};

export default createReducer(initialState, {
  [selectPanelAction.type]: (state, action: PayloadAction<string>) => {
    state.selectedPanel = action.payload;
  },
});
