import queryString, { ParsedUrlQueryInput } from 'querystring';
import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createAction, createReducer, PayloadActionCreator, PayloadAction } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../../../config';
import { fetchApi, getOptions } from '../../sagas';
import {
  ComplaintUpdater,
  ComplaintSelector,
  ComplaintsFilter,
  PageOfComplaints,
  State,
  ComplaintCreator,
} from './types';
import { PostId } from '../posts/types';
import { Saga } from '../../sagasTypes';
import { fetchPostByIdAction, fetchPostsAction } from '../posts';
import { ContextType } from '../../../enums/ContextType';
import { fetchCommentByIdAction, fetchCommentsAction } from '../comments';
import { fetchDiscussionsAction } from '../discussions';

export const fetchComplaintsAction: PayloadActionCreator<ComplaintsFilter | undefined> = createAction(
  'complaint/saga/fetch/complaints'
);
export const filterComplaintAction: PayloadActionCreator<ComplaintsFilter | undefined> =
  createAction('complaint/filter');
export const resolveComplaintAction: PayloadActionCreator<ComplaintUpdater> = createAction('complaint/resolve');
export const selectComplaintAction: PayloadActionCreator<ComplaintSelector | unknown> =
  createAction('complaint/select');
export const createComplaintAction: PayloadActionCreator<ComplaintCreator> = createAction(
  'complaint/saga/create/complaint'
);
const receivedComplaintCreationAction: PayloadActionCreator<ComplaintCreator> = createAction(
  'complaint/saga/created/complaint'
);
const deletedContextAction: PayloadActionCreator<unknown> = createAction('complaint/saga/deleted/context');
const errorComplaintAction: PayloadActionCreator<unknown> = createAction('complaint/failed/fetch');
const receivedComplaintsAction: PayloadActionCreator<PageOfComplaints> = createAction(
  'complaint/saga/received/complaints'
);
const receivedContextPostIdOfCommentAction: PayloadActionCreator<PostId> = createAction(
  'complaint/saga/received/context/postIdOfComment'
);
const updatedComplaintAction: PayloadActionCreator<ComplaintUpdater> = createAction('complaint/saga/updated/complaint');

export const sagas = [
  takeLatest([fetchComplaintsAction, filterComplaintAction], fetchComplaints),
  takeLatest(selectComplaintAction, fetchComplaintContext),
  takeLatest(resolveComplaintAction, updateComplaint),
  takeLatest(createComplaintAction, createComplaint),
];

const initialState: State = {
  complaints: [],
  context: {}, // the context of the selected complaint
  error: null,
  isLoading: true,
  isResolvedRecord: {
    [ContextType.Post]: false,
    [ContextType.Comment]: false,
    [ContextType.Discussion]: false,
  },
  currentComplaintsContextType: ContextType.Discussion,
  nextPageId: null, // pagination value for complaints api
  selected: null, // id of the selected complaint
};

export default createReducer(initialState, {
  [fetchComplaintsAction.type]: (state: State) => {
    state.isLoading = true;
  },
  [createComplaintAction.type]: (state: State) => {
    state.isLoading = true;
  },
  [receivedComplaintCreationAction.type]: (state: State) => {
    state.isLoading = false;
  },
  [receivedComplaintsAction.type]: (state: State, action: PayloadAction<PageOfComplaints>) => {
    const { nextPageId, complaints } = action.payload;
    state.isLoading = false;
    state.nextPageId = nextPageId;
    state.complaints.splice(0, state.complaints.length, ...complaints);
  },
  [selectComplaintAction.type]: (state: State, action: PayloadAction<ComplaintSelector>) => {
    const { id: complaintId } = action.payload;
    state.selected = complaintId;
  },
  [filterComplaintAction.type]: (state: State, action: { payload: ComplaintsFilter }) => {
    if (action.payload?.contextType) {
      const { isResolved, contextType } = action.payload;
      state.currentComplaintsContextType = contextType;
      if (isResolved !== undefined) state.isResolvedRecord[contextType] = Boolean(isResolved);
      else state.isResolvedRecord[contextType] = undefined;
    } else if (action.payload) {
      const { isResolved } = action.payload;
      state.currentComplaintsContextType = ContextType.Discussion;
      if (isResolved !== undefined) state.isResolvedRecord.discussion = Boolean(isResolved);
    } else state.isResolvedRecord.discussion = undefined;
  },
  [resolveComplaintAction.type]: (state: State) => {
    state.selected = null;
    state.context = {};
  },
  [errorComplaintAction.type]: (state: State, action: PayloadAction<unknown>) => {
    const error = action.payload;
    console.error(error);
    state.isLoading = false;
  },
});

export function* fetchComplaints(action: { payload?: ComplaintsFilter }): Saga {
  const query = action.payload ? `&${queryString.stringify(action.payload as ParsedUrlQueryInput)}` : '';
  yield call(
    fetchApi,
    `${API_BASE_URL}/v1/community/complaints?pageLength=10000${query}`,
    getOptions(),
    receivedComplaintsAction,
    errorComplaintAction
  );
}

export function* fetchComplaintContext(action: PayloadAction<ComplaintSelector>): Saga {
  const { contextId, contextType } = action.payload;

  if (!contextId || !contextType) {
    return;
  }

  if (contextType === ContextType.Post) {
    yield put(fetchPostByIdAction({ postId: contextId }));
  } else if (contextType === ContextType.Comment) {
    const commentId = contextId;
    yield put(fetchCommentByIdAction({ commentId }));
  }
}

export function* updateComplaint(action: PayloadAction<ComplaintUpdater>): Saga {
  const { complaintId, note, isDelete, contextId, contextType, reason } = action.payload;
  let postId;

  if (isDelete) {
    if (contextType === ContextType.Post) {
      postId = contextId;
      yield call(
        fetchApi,
        `${API_BASE_URL}/v1/community/posts/${postId}`,
        getOptions('DELETE'),
        deletedContextAction,
        errorComplaintAction
      );
    } else if (contextType === ContextType.Comment) {
      const commentId = contextId;
      const action: PayloadAction<PostId> = yield call(
        fetchApi,
        `${API_BASE_URL}/v1/community/comments/${commentId}`,
        getOptions(),
        receivedContextPostIdOfCommentAction,
        errorComplaintAction
      );
      postId = action.payload.postId;
      yield call(
        fetchApi,
        `${API_BASE_URL}/v1/community/posts/${postId}/comments/${commentId}`,
        getOptions('DELETE'),
        deletedContextAction,
        errorComplaintAction
      );
    }
  }
  yield call(
    fetchApi,
    `${API_BASE_URL}/v1/community/complaints/${complaintId}`,
    getOptions('PATCH', { note, isResolved: true, reason }),
    updatedComplaintAction,
    errorComplaintAction
  );

  // refetch data after updating a complaint
  const currentComplaintsContextType = yield select((state) => state.complaints.currentComplaintsContextType);
  switch (currentComplaintsContextType) {
    case ContextType.Post: {
      const postsStatusResolvedStatus = yield select((state) => state.complaints.isResolvedRecord[ContextType.Post]);
      const postsStatusFilter =
        postsStatusResolvedStatus === undefined
          ? { contextType: ContextType.Post }
          : { isResolved: postsStatusResolvedStatus, contextType: ContextType.Post };
      yield put(fetchComplaintsAction(postsStatusFilter));
      break;
    }
    case ContextType.Comment: {
      const commentsStatusResolvedStatus = yield select(
        (state) => state.complaints.isResolvedRecord[ContextType.Comment]
      );
      const commentsStatusFilter =
        commentsStatusResolvedStatus === undefined
          ? { contextType: ContextType.Comment }
          : { isResolved: commentsStatusResolvedStatus, contextType: ContextType.Comment };
      yield put(fetchComplaintsAction(commentsStatusFilter));
      break;
    }
    default: {
      const discussionsStatusResolvedStatus = yield select(
        (state) => state.complaints.isResolvedRecord[ContextType.Discussion]
      );
      const discussionsStatusFilter =
        discussionsStatusResolvedStatus === undefined ? undefined : { isResolved: discussionsStatusResolvedStatus };
      yield put(fetchComplaintsAction(discussionsStatusFilter));
      break;
    }
  }
}

export function* createComplaint(action: PayloadAction<ComplaintCreator>): Saga {
  const { contextId, contextType } = action.payload;
  yield call(
    fetchApi,
    `${API_BASE_URL}/v1/community/complaints`,
    getOptions('POST', { contextId, contextType, timestamp: moment().toISOString() }),
    receivedComplaintCreationAction,
    errorComplaintAction
  );

  // refetch data after creating a complaint
  const postsStatusFilter = yield select((state) => state.posts.postsStatusFilter);
  yield put(fetchPostsAction(postsStatusFilter));

  const commentsStatusFilter = yield select((state) => state.comments.commentsStatusFilter);
  yield put(fetchCommentsAction(commentsStatusFilter));

  const discussionsStatusFilter = yield select((state) => state.discussions.discussionsStatusFilter);
  yield put(fetchDiscussionsAction(discussionsStatusFilter));
}
