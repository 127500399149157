import React, { MouseEventHandler, ReactNode } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { createStyles, ListItem, ListItemIcon, ListItemText, Theme, WithStyles } from '@material-ui/core';

const styles = createStyles((theme: Theme) => ({
  icon: {
    paddingLeft: theme.spacing(0.5),
  },
  iconFocused: {
    color: theme.palette.primary.main,
    '& svg path': {
      fill: theme.palette.primary.main,
    },
  },
}));

export interface ListItemLinkProps extends WithStyles {
  icon: ReactNode;
  primary: ReactNode;
  to: string;
  selected: boolean;
  handleClick: MouseEventHandler<HTMLAnchorElement>;
}

const ListItemLink = ({ classes, icon, primary, to, selected, handleClick }: ListItemLinkProps) => {
  return (
    <>
      <ListItem
        className={classes.item}
        button
        component={React.forwardRef<HTMLAnchorElement, React.HTMLProps<HTMLAnchorElement>>((props, ref) => (
          <Link to={to} {...props} ref={ref} />
        ))}
        onClick={handleClick}
      >
        <ListItemIcon className={clsx(classes.icon, { [classes.iconFocused]: selected })}>{icon}</ListItemIcon>
        <ListItemText className={clsx({ [classes.iconFocused]: selected })} primary={primary} />
      </ListItem>
    </>
  );
};

export default withStyles(styles)(ListItemLink);
