import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { createStyles, Divider, Drawer, IconButton, List, Theme, withStyles, WithStyles } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon, Menu as MenuIcon } from '@material-ui/icons';
import ListItemLink from '../../components/ListItemLink';
import Login from '../../components/Login';
import { drawerWidth } from '.';

const styles = createStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

export interface AppDrawerProps extends WithStyles {
  isDrawerOpen: boolean;
  panels: Array<{
    name: string | null;
    path: string;
    icon?: ReactNode;
    isHidden?: boolean;
  }>;
  activePanel: string | null;
  toggleDrawer: () => void;
  handlePanelClick: (name: string | null) => void;
}

const AppDrawer = ({ classes, isDrawerOpen, panels, activePanel, toggleDrawer, handlePanelClick }: AppDrawerProps) => (
  <Drawer
    className={clsx(classes.drawer, {
      [classes.drawerOpen]: isDrawerOpen,
      [classes.drawerClose]: !isDrawerOpen,
    })}
    variant="permanent"
    classes={{
      paper: clsx(
        { overflow: 'hidden' },
        {
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }
      ),
    }}
    open={isDrawerOpen}
  >
    <div className={classes.toolbar} onClick={toggleDrawer}>
      {isDrawerOpen ? (
        <IconButton aria-label="close drawer">
          <ChevronLeftIcon />
        </IconButton>
      ) : (
        <IconButton aria-label="open drawer">
          <MenuIcon />
        </IconButton>
      )}
    </div>
    <Divider />
    <List component="nav">
      {panels.map(({ path, name, icon, isHidden }) => {
        return isHidden ? null : (
          <ListItemLink
            icon={icon}
            key={path}
            primary={name}
            selected={name === activePanel}
            to={path}
            handleClick={() => handlePanelClick(name)}
          />
        );
      })}
    </List>
    <Divider />
    <Login />
  </Drawer>
);

export default withStyles(styles)(AppDrawer);
