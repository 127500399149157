import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { AppBar, createStyles, Link, Toolbar, Typography, withStyles, WithStyles } from '@material-ui/core';
import { CustomTheme } from '../..';
import { drawerWidth } from '.';

const styles = createStyles((theme: CustomTheme) => ({
  appBar: {
    marginLeft: theme.spacing(8) + 1,
    width: `calc(100% - ${theme.spacing(8) + 1}px)`,
    backgroundColor: theme.palette.appBar.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarHome: {
    color: theme.palette.appBar.main,
  },
  appBarText: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  appBarSubText: {
    color: theme.palette.appBar.light,
  },
  versionText: {
    color: theme.palette.appBar.versionText,
    whiteSpace: 'nowrap',
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleBarItems: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    alignItems: 'center',
  },
}));

export interface AppContainerProps extends WithStyles {
  isDrawerOpen: boolean;
  selectedPanel: string | null;
  onHomeClick: MouseEventHandler<HTMLHeadingElement>;
}

const AppContainer = ({ classes, isDrawerOpen, selectedPanel, onHomeClick }: AppContainerProps) => (
  <AppBar position="sticky" className={clsx(classes.appBar, { [classes.appBarShift]: isDrawerOpen })}>
    <Toolbar className={classes.titleBar}>
      <div className={classes.titleBarItems}>
        <Typography
          className={clsx(classes.appBarText, { [classes.appBarHome]: !selectedPanel })}
          variant="h6"
          noWrap
          onClick={onHomeClick}
        >
          Management Console
          {selectedPanel && (
            <React.Fragment>
              <strong>{' > '}</strong>
              <Typography display="inline" className={clsx(classes.appBarSubText)} style={{ fontWeight: 500 }}>
                {selectedPanel}
              </Typography>
            </React.Fragment>
          )}
        </Typography>
      </div>
      <Link className={classes.versionText} href="/version.txt">
        Version Info
      </Link>
    </Toolbar>
  </AppBar>
);

export default withStyles(styles)(AppContainer);
