// import Auth0Lock from 'auth0-lock';
// import { Auth0UserProfile } from 'auth0-js';
import jwtDecode, { JwtPayload } from 'jwt-decode';
// import * as config from '../config';

// Configure Auth0 lock
// export const lock = new Auth0Lock(config.auth0.CLIENT_ID, config.auth0.DOMAIN, {
//   auth: {
//     redirectUrl: `${window.location.origin}/`,
//     responseType: 'token id_token',
//     redirect: false,
//     sso: true,
//     params: { scope: 'openid profile email' },
//   },
//   theme: {
//     logo: 'https://carrot.co/wp-content/uploads/2018/10/logo-carrot.svg',
//   },
//   languageDictionary: {
//     title: 'Management Console',
//   },
//   autofocus: true,
//   closable: false,
// });

// export const login = () => {
//   // Call the show method to display the widget.
//   lock.show();
// };

export const loggedIn = () => {
  // Checks if there is a saved token and it's still valid
  return !isTokenExpired();
};

export const logout = () => {
  // Clear user token, market and profile data from window.localStorage
  window.localStorage.removeItem('id_token');
  window.localStorage.removeItem('profile');
  window.localStorage.removeItem('market');
};

// export const getProfile = (): Auth0UserProfile => {
//   // Retrieves the profile data from window.localStorage
//   const profile = window.localStorage.getItem('profile');
//   return profile ? JSON.parse(window.localStorage.profile) : {};
// };

// export const setProfile = (profile: Auth0UserProfile) => {
//   // Saves profile data to window.localStorage
//   window.localStorage.setItem('profile', JSON.stringify(profile));
//   // Triggers profile_updated event to update the UI
// };

export const setToken = (idToken: string) => {
  // Saves user token to window.localStorage
  window.localStorage.setItem('id_token', idToken);
};

export const getToken = () => {
  // Retrieves the user token from window.localStorage
  return window.localStorage.getItem('id_token');
};

export const setMarket = (idToken: string) => {
  // Saves user market to window.localStorage
  window.localStorage.setItem('market', idToken);
};

export const getMarket = () => {
  // Retrieves the user market from window.localStorage
  return window.localStorage.getItem('market');
};

export const getTokenExpirationDate = () => {
  const token = getToken();
  if (!token) {
    return null;
  }

  const decoded = jwtDecode<JwtPayload>(token);
  if (!decoded.exp) {
    return null;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
};

export const isTokenExpired = () => {
  const token = getToken();
  if (!token) {
    return true;
  }

  const date = getTokenExpirationDate();
  const offsetSeconds = 0;
  if (date === null) {
    return false;
  }

  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
};
