import React from 'react';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';

const styles = createStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

const SelectPanel = ({ classes }: WithStyles) => (
  <div className={classes.root}>
    <Typography variant="h4">Welcome! Select a panel to get started.</Typography>
  </div>
);

export default withStyles(styles)(SelectPanel);
