import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

const PrivateRoute = (props) => {
  const { auth, component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={
        (props) => <Component {...props} />
        // auth.isAuthenticated ? (
        //   <Component {...props} />
        // ) : (
        //   <Redirect
        //     to={{
        //       pathname: '/',
        //       state: {
        //         from: props.location,
        //       },
        //     }}
        //   />
        // )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
