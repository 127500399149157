import { reducer as formReducer } from 'redux-form';
import app from './app';
// import auth from './auth';
import complaints from './complaints';
import posts from './posts';
import comments from './comments';
import discussions from './discussions';

const reducer = {
  app,
  // auth,
  complaints,
  posts,
  comments,
  discussions,
  form: formReducer,
};

export default reducer;
