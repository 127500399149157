import React, { ComponentType, Suspense } from 'react';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import NotFound from '../../components/NotFound';

const styles = (theme: Theme) => ({
  content: {
    height: '100%',
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: 'auto',
  },
});

export interface MainContentProps extends WithStyles {
  routes: Array<{
    path: string;
    subPath?: string;
    exact?: boolean;
    isPublic?: boolean;
    component?: ComponentType<any>;
  }>;
}

const MainContent = ({ classes, routes }: MainContentProps) => {
  return (
    <div className={classes.content}>
      <Suspense fallback={<Typography variant="caption">Loading...</Typography>}>
        <Switch>
          {routes.map(({ isPublic, component, subPath, path, ...restOfRouteProps }, index) =>
            isPublic ? (
              <Route key={index} component={component} path={subPath ?? path} {...restOfRouteProps} />
            ) : (
              <PrivateRoute key={index} component={component} path={subPath ?? path} {...restOfRouteProps} />
            )
          )}
          <Route render={NotFound} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default withStyles(styles)(MainContent);
