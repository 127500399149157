import React, { useCallback, useState } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  // Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import LoggedOutIcon from '@material-ui/icons/AccountBoxSharp';
// import { RootState } from '../redux/modules/rootState';
// import * as authModule from '../redux/modules/auth';
import { connect, ConnectedProps } from 'react-redux';
import { setMarket, setToken as setTokenFromLocalStorage } from '../utils/AuthService';
// import { useHistory } from 'react-router';

const styles = createStyles(() => ({
  login: {
    marginTop: 'auto',
    overflow: 'hidden',
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: '56px',
    width: '56px',
    margin: '4px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  greeting: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    height: '56px',
    width: '56px',
    margin: '4px',
  },
}));

// const mapStateToProps = ({ auth }: RootState) => ({
//   isAuthenticated: auth.isAuthenticated,
//   profile: auth.profile,
//   error: auth.error,
// });

// const mapDispatchToProps = {
//   loginRequestAuthAction: authModule.loginRequestAuthAction,
//   logoutSuccessAuthAction: authModule.logoutSuccessAuthAction,
// };

// const connector = connect(mapStateToProps, mapDispatchToProps);
const connector = connect(null, {});
export type LoginProps = ConnectedProps<typeof connector> & WithStyles;

const Login = ({
  classes,
}: // isAuthenticated,
// profile,
// error,
// loginRequestAuthAction,
// logoutSuccessAuthAction,
LoginProps) => {
  // const history = useHistory();
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState('');

  const handleLoginClick = useCallback(() => {
    // loginRequestAuthAction();
    // }, [loginRequestAuthAction]);
    setOpen(true);
    setToken('');
  }, []);
  const handleSetToken = () => {
    setOpen(false);
    setTokenFromLocalStorage(token);
    setMarket('en-GB');
  };
  const handleClose = () => {
    setOpen(false);
  };
  // const handleLogoutClick = useCallback(() => {
  //   logoutSuccessAuthAction();
  //   history.push('/');
  //   loginRequestAuthAction();
  // }, [history, logoutSuccessAuthAction, loginRequestAuthAction]);

  return (
    <div className={classes.login}>
      <div className={classes.profile}>
        {/* {isAuthenticated ? (
          <img className={classes.avatar} src={profile.picture as string} alt="profile" />
        ) : ( */}
        <LoggedOutIcon classes={{ root: classes.icon }} fontSize="large" />
        {/* )} */}
        <div className={classes.greeting}>
          {/* <Typography variant="body1">{isAuthenticated ? (profile.name as string) : 'Please log in'}</Typography>
          {isAuthenticated ? (
            <Button onClick={handleLogoutClick} size="small" variant="outlined">
              Logout
            </Button>
          ) : ( */}
          <Button onClick={handleLoginClick} size="small" variant="outlined">
            Login
          </Button>
          {/* )} */}
        </div>
      </div>
      {/* {error ? <p>{JSON.stringify(error)}</p> : null} */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Please enter the API Key</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="apiKey"
            label="API Key"
            fullWidth
            variant="standard"
            onChange={(props) => {
              setToken(props.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSetToken}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connector(withStyles(styles)(Login));
