import React, { lazy, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import clsx from 'clsx';
import { ReactComponent as IconCommunityModeration } from '../../static/svg-icons/icon-community-moderation.svg';
import { ReactComponent as IconComplaints } from '../../static/svg-icons/icon-complaints.svg';

import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import { createStyles, CssBaseline, Theme, withStyles, WithStyles } from '@material-ui/core';
import { selectPanelAction } from '../../redux/modules/app';
// import * as AuthService from '../../utils/AuthService';
import MainContent from './MainContent';
import SelectPanel from './SelectPanel';
import AppContainer from './AppContainer';
import AppDrawer from './AppDrawer';

const CommunityModeration = lazy(() => import('../CommunityModeration'));
const Complaints = lazy(() => import('../Complaints'));

export const navBarHeight = 64;
export const drawerWidth = 260;

const styles = createStyles((theme: Theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  contentWrapperOpenedDrawer: {
    height: `calc(100% - ${navBarHeight}px)`,
    width: `calc(100% - ${drawerWidth}px)`,
    position: 'fixed',
    top: `${navBarHeight}px`,
    right: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentWrapperClosedDrawer: {
    height: `calc(100% - ${navBarHeight}px)`,
    width: `calc(100% - ${theme.spacing(8) + 1}px)`,
    position: 'fixed',
    top: `${navBarHeight}px`,
    right: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const mapStateToProps = (state: any) => ({
  selectedPanel: state.app.selectedPanel,
});

const mapDispatchToProps = {
  selectPanelAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type AppProps = ConnectedProps<typeof connector> & WithStyles & RouteComponentProps;

const App = ({ classes, selectedPanel, selectPanelAction }: AppProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const panels = useMemo(
    () => [
      {
        name: null,
        isHidden: true,
        path: '/',
        exact: true,
        isPublic: true,
        component: SelectPanel,
      },
      {
        name: 'Community Moderation',
        icon: <IconCommunityModeration />,
        path: '/community-moderation',
        component: CommunityModeration,
      },
      {
        name: 'Complaints',
        icon: <IconComplaints />,
        path: '/complaints',
        component: Complaints,
      },
    ],
    []
  );

  // useEffect(() => {
  //   AuthService.lock.on('authenticated', (authResult) => {
  //     AuthService.lock.getUserInfo(authResult.accessToken, (err, profile) => {
  //       if (err) {
  //         loginErrorAuthAction(err);
  //         return;
  //       }
  //       AuthService.setToken(authResult.idToken);
  //       AuthService.setProfile(profile);

  //       loginSuccessAuthAction(profile);

  //       history.push('/');
  //       AuthService.lock.hide();
  //     });
  //   });

  //   AuthService.lock.on('authorization_error', (err) => {
  //     loginErrorAuthAction(err);
  //     history.push('/');
  //   });

  //   if (!AuthService.loggedIn()) {
  //     AuthService.login();
  //   }
  // }, [history, loginErrorAuthAction, loginSuccessAuthAction]);

  useEffect(() => {
    for (const panel of panels) {
      if (location.pathname === panel.path) {
        selectPanelAction(panel.name);
        if (!panel.name) {
          openDrawer();
        }
        break;
      }
    }
  }, [location, panels, selectPanelAction]);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const handleClickHome = () => {
    selectPanelAction(null);
    openDrawer();
    history.push('/');
  };

  const handleClickPanel = (panelName: string | null) => {
    if (panelName) {
      closeDrawer();
    }
    selectPanelAction(panelName);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppContainer isDrawerOpen={isDrawerOpen} onHomeClick={handleClickHome} selectedPanel={selectedPanel} />
      <AppDrawer
        isDrawerOpen={isDrawerOpen}
        activePanel={selectedPanel}
        panels={panels}
        toggleDrawer={toggleDrawer}
        handlePanelClick={handleClickPanel}
      />
      <main
        className={clsx({
          [classes.contentWrapperOpenedDrawer]: isDrawerOpen,
          [classes.contentWrapperClosedDrawer]: !isDrawerOpen,
        })}
      >
        <MainContent routes={panels} />
      </main>
    </div>
  );
};

export default withRouter(connector(withStyles(styles)(App)));
