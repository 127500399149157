import React from 'react';
import { Icon, Typography } from '@material-ui/core';

const NotFound = () => (
  <React.Fragment>
    <Icon color="error" fontSize="large" style={{ fontSize: 60 }}>
      pool
    </Icon>
    <Typography variant="h4">Oops, page not found.</Typography>
  </React.Fragment>
);

export default NotFound;
